:root {
  --color-burnt-orange: #b67e33;
  --color-olive-green: #9e9e30;
  --color-teal: #347c64;
  --color-dark-green: #092a1e;
  --color-white: #ffffff;
  --color-black: #000000;
  --font-primary: "Gilroy", sans-serif;
  --font-secondary: "Gilroy", sans-serif;
  --font-heading: "Gilroy", sans-serif;
  --gray-white: #fcfbf8;
  --dark-black: #302f2f;
}

* {
  margin: 0px;
  padding: 0px;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/Gilroy-Bold.ttf");
  font-display: block;
}

body {
  font-family: "Gilroy", sans-serif;
  font-size: 1.25rem;
}

h1,
h2,
h3,
h4 {
  line-height: 0.98;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

h1 {
  font-size: 8.5rem;
  font-weight: 900;
  color: var(--color-white);
}

h2 {
  font-size: 6rem;
  font-weight: 900;
  color: var(--color-white);
}

h3 {
  font-size: 2.25rem;
  font-weight: 900;
  color: var(--color-black);
}

h4 {
  font-size: 1.875rem;
  font-weight: 500;
  margin-top: 1rem;
}

p {
  color: var(--dark-black);
  line-height: 1.5;
  font-weight: 300;
}

button{
  font-size: 1.125rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  line-height: 1.5;
  border: 1px solid var(--color-burnt-orange);
  color: var(--color-burnt-orange);
  cursor: pointer;
  background: transparent;
  transition: ease-out 0.8s;
  font-family: "Gilroy", sans-serif;
}
.visit-button a {
  text-decoration: none;
  color: var(--color-white);
  border: 1px solid var(--color-white);
  padding: 0.8rem 2rem;
  font-weight: 500;
  margin-top: 2rem;
  transition: ease-out 0.8s;
}
button:hover , .visit-button a:hover{
  box-shadow: inset 0 5rem 0 0 var(--color-burnt-orange);
  color: var(--color-white);
}

section {
  padding: 4rem 0;
}

.container {
  padding: 0 4rem;
}
#loom-companion-mv3 {
  display: none !important;
}

/* LoadingSection CSS  start------------------------------------------------------------------- */
figure {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 6.25em;
  height: 6.25em;
  animation: rotate 2.4s linear infinite;
}

.white {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-white);
  animation: flash 2.4s linear infinite;
  opacity: 0;
}

.dot {
  position: absolute;
  margin: auto;
  width: 2.4em;
  height: 2.4em;
  border-radius: 100%;
  transition: all 1s ease;
}

.dot:nth-child(2) {
  top: 0;
  bottom: 0;
  left: 0;
  background: var(--color-burnt-orange);
  animation: dotsY 2.4s linear infinite;
}

.dot:nth-child(3) {
  left: 0;
  right: 0;
  top: 0;
  background: var(--color-teal);
  animation: dotsX 2.4s linear infinite;
}

.dot:nth-child(4) {
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--color-olive-green);
  animation: dotsY 2.4s linear infinite;
}

.dot:nth-child(5) {
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-dark-green);
  animation: dotsX 2.4s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  10% {
    width: 6.25em;
    height: 6.25em;
  }

  66% {
    width: 2.4em;
    height: 2.4em;
  }

  100% {
    transform: rotate(360deg);
    width: 6.25em;
    height: 6.25em;
  }
}

@keyframes dotsY {
  66% {
    opacity: 0.1;
    width: 2.4em;
  }

  77% {
    opacity: 1;
    width: 0;
  }
}

@keyframes dotsX {
  66% {
    opacity: 0.1;
    height: 2.4em;
  }

  77% {
    opacity: 1;
    height: 0;
  }
}

@keyframes flash {
  33% {
    opacity: 0;
    border-radius: 0%;
  }

  55% {
    opacity: 0.6;
    border-radius: 100%;
  }

  66% {
    opacity: 0;
  }
}

/* LoadingSection CSS  end---------------------------------------------------------------------- */

/*-------header------*/
.container {
  position: relative;
}

.header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 35px;
  height: 5px;
  background-color: var(--color-white);
  margin: 5px 0;
  transition: 0.4s;
  border-radius: 10px;
}

.bar.small {
  width: 25px;
  margin-left: auto;
}

.header-nav {
  display: flex;
  gap: 30px;
}

.header-nav.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px; 
  left: 0;
  width: 100%;
  background-color: var(--color-white); 
}

nav {
  padding: 1.1rem 0;
  background-color: var(--dark-black);
}

.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo img {
  height: 3.5rem;
}
.header-nav.open .header-home{
  padding: 20px;
}

.header-nav a {
  color: var(--color-white);
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  padding: 0.5rem 0;
}

.header-nav a:hover {
  color: var(--color-burnt-orange);
}

/*----------hero-section------------*/

@keyframes hero-animation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

h1 span {
  background: linear-gradient(
    270deg,
    var(--color-burnt-orange),
    var(--color-olive-green),
    var(--color-teal)
  );
  background-size: 800% 800%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: hero-animation 2s ease infinite;
}

.hero-section {
  background-color: var(--dark-black);
}

.hero-text {
  max-width: 65rem;
  margin: 0 auto;
  text-align: center;
}

.hero-text p {
  font-size: 2rem;
  font-weight: 500;
  color: var(--color-olive-green);
  margin-top: 0.75rem;
  line-height: 1.2;
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

/* ----------pop-up------------- */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: var(--color-white);
  padding: 20px;
  border-radius: 8px;
  width: 75%;
  position: relative;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: var(--color-burnt-orange);
  padding: 0px 5px;
}

.popup-image {
  width: 40%;
  aspect-ratio: 1 / 1;
}

.popup-text {
  width: 60%;
}

.popup-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.main-pop-up {
  display: flex;
  gap: 2rem;
}

.popup-description {
  margin-top: 10px;
}

/* ----------crouselstudy-slider------------ */
/* .reviewText p:first-child::before {
  content: '“'; 
  font-size: 1.2em; 
}
.reviewText p:last-child::after {
  content: '”';
  font-size: 1.2em; 
} */
.reviewText {
  max-width: 80%;
  margin: auto;
}
.reviewText p{
  font-style: italic;
}
.reviewText ul {
  margin-left: 2rem;
}
.reviewText ul li {
  line-height: 1.6;
  font-weight: 300;
  font-style: italic;
  color: var(--dark-black);
}
.crousel-study-main {
  display: flex !important;
  gap: 2rem;
}

.crousel-study-text {
  width: 50%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.ytp-pause-overlay, .ytp-endscreen-content {
  display: none !important;
}

.case-study-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.arrow-button {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.custom-arrow {
  border: 2px solid;
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
}

span.arrow-icon {
  font-weight: 500;
}

.crousel-study-image {
  width: 50%;
  display: flex;
}

.crousel-image-box {
  width: 100%;
  aspect-ratio: 4 / 3; 
}

.crousel-image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.slick-arrow.slick-next {
  display: none !important;
}

.review-slider .crousel-image-box {
  width: 100%;
 aspect-ratio: 1/1;
}

.review-slider .crousel-study-image {
  width: 30%;
  display: flex;
}

.review-slider .crousel-study-text {
  width: 70%;
}

.review-slider .text-crousel {
  width: 75%;
}

.review-slider .crousel-image-box img {
  width: 100%;
  height: 85%;
  max-height: 450px;
  margin-bottom: 1rem;
}

/* -----------Brand-section---------- */

.brand-section {
  background-color: var(--dark-black);
}

.brand-title {
  max-width: 1100px;
}

.brand-description {
  max-width: 912px;
  margin-bottom: 2rem;
}

.brand-description p {
  color: var(--color-white);
}

.brand-images {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 2rem;
  margin: 2rem 0;
}

.brand-image {
  width: 100%;
  aspect-ratio: 1/0;
  position: relative;
}
.brand-image-text {
  position: absolute;
  top: 0;
  left: 2rem;
  color: var(--color-white);
  text-transform: uppercase;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.brand-image-text h3 {
  padding-top: 2rem;
  color: var(--color-white);
}
.brand-image-text p{
  color: var(--color-white);
  text-transform: capitalize;
  padding-bottom: 2rem;
}

.brand-image img:hover .brand-image-text p {
  display: none !important;
}

.brand-text p {
  color: var(--color-white);
  margin: 1rem 0;
}

.brand-text {
  width: 70%;
  max-width: 1200px;
}

.brand-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.2;
}

.brand-image img:hover {
  opacity: 1;
}

/* -----------member-section----------- */

.member-section1 {
  background: var(--color-olive-green);
}

.member-section {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.member-content p {
  color: var(--color-white);
}

.member-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  align-items: end;
}

.member-text, .brand-footer {
  width: 75%;
}

.member-text h3 {
  color: var(--color-white);
  margin-bottom: 3rem;
}

.member-image {
  width: 50%;
  height: 500px;
}

.member-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* ---achiemenet--- */

section.logo-card-section {
  background-color: var(--gray-white);
}
.achievement-content {
  max-width: 100%;
  margin: auto;
  text-align: center;
}
.achievement_img {
  width: 100%;
}
.achievement_img img {
  width: 60%;
}

section.our-achievements {
  background-color: var(--color-teal);
}

.achievement-text {
  text-align: center !important;
}

.achievement-image img {
  width: 100%;
  height: 100%;
  max-height: 450px;
  object-fit: cover; 
}

.achievement-text p {
  color: var(--color-white);
  width: 80%;
  margin: auto;
}

button.white-button.visit-button {
  width: 20%;
  margin-top: 1em;
}

/* ------------card-section----------- */

.logo-card-header {
  margin-bottom: 2rem;
}
.logo-card-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2rem;
}

.logo-card-header h3 {
  font-size: 2.75rem;
  font-weight: 500;
  line-height: 1.2;
}

.logo-card {
  border: 1px solid var(--color-burnt-orange);
  border-radius: 0.5rem;
  padding: 1rem;
  box-sizing: border-box;
  height: 150px;
}

img.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-title {
  color: var(--color-dark-green);
  margin: 1rem 0 0.5rem;
}

.card-description {
  font-size: 1rem;
  color: var(--color-burnt-orange);
}

/* -------------section-------------- */
.team-redirect-section {
  display: flex;
  justify-content: center;
  background-color: var(--dark-black);
  text-align: center;
}

.team-redirect-content {
  max-width: 1000px;
}

/* ------------discovery-section------------ */
.discovery-section {
  background-color: var(--color-teal);
}

.discovery-main {
  display: flex;
  gap: 2rem;
}

.discovery-image {
  width: 50%;
  align-content: center;
}

.discovery-image img {
  width: 60%;
  float: right;
}

.discovery-content {
  width: 50%;
  align-content: center;
}

.discovery-description {
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 450px;
}
.discovery-description h3,
.discovery-description p {
  color: var(--color-white);
  margin-bottom: 1rem;
}

.white-button {
  border: 1px solid var(--color-white);
  color: var(--color-white);
}

.white-button:hover {
  border: 1px solid var(--color-burnt-orange);
  box-shadow: inset 0 5rem 0 0 var(--color-burnt-orange);
  color: var(--color-white);
}

.team-redirect-button {
  margin-top: 1rem;
}

/* ---------------------------Service-page--------brand-section------------ */

.service-brand {
  display: flex;
  background: var(--gray-white);
}

.service-brand:nth-child(2) {
  flex-direction: row-reverse;
  background-color: var(--color-burnt-orange);
}

.service-brand-image {
  width: 40%;
}

.service-brand-image img {
  width: 100%;
  height: 100%;
}

.service-brand-content {
  flex: 2;
  padding: 4rem;
}
.service-brand-content hr {
  width: 80%;
}
section.service-brand-section,
.service-brand-section .container {
  padding: 0;
}

hr {
  margin: 1rem 0;
}

/* ------------service-approach---------- */

.service-approach-header {
  margin-bottom: 2rem;
}

h2.service-approach-header-title {
  width: 75%;
  color: var(--color-black);
}

.service-approach-numbers {
  margin-bottom: 2rem;
  text-align: center;
}

.service-approach-number {
  color: var(--color-dark-green);
}

.service-approach-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.service-brand:nth-child(2) .service-brand-content p,.service-brand:nth-child(2) .service-brand-content h3 {
  color: var(--color-white);
}

/* -----------serive-look-section------------- */

.serviceLook-section {
  background-color: var(--gray-white);
  text-align: center;
}

.serviceLook-content {
  max-width: 1000px;
  margin: 0 auto;
}

.serviceLook-title {
  color: var(--color-black);
  margin-bottom: 1rem;
}

/* -----------work-page--------- */

.work-nav-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2rem;
  align-items: center;
  padding: 2rem;
  text-align: center;
}

.work-nav-item {
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
}

.work-nav-item:hover {
  color: var(--color-teal);
  text-decoration: underline;
}

.work-nav-divider {
  border: none;
  border-top: 1px solid var(--dark-black);
  margin: 0;
}

.work-nav-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 2rem;
  gap: 4rem;
}

.work-image {
  width: 100%;
  aspect-ratio: 1.4 / 1;
}

.work-image img {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.work-text {
  height: 20%;
  padding: 1rem 0 1rem 0;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.pagination-button {
  border: 1px solid var(--color-olive-green);
  border-radius: 4px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 16px;
}

.pagination-button:hover {
  box-shadow: none;
  background: #b67e33;
}

.pagination-button.active {
  background-color: var(--color-burnt-orange);
  border: 1px solid var(--color-burnt-orange);
  color: var(--gray-white);
}

/* -------------about-page------------- */

.about-grow-section {
  background-color: var(--color-burnt-orange);
}

.about-grow-heading {
  color: var(--color-white);
}

.about-grow-description {
  color: var(--color-white);
  width: 80%;
  margin: 2rem auto;
  /* max-width: 600px; */
}

.about-grow-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.about-grow-card {
  background-color: var(--color-white);
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px var(--dark-black);
  padding: 1.5rem;
  min-height: 100px;
}

.about-grow-card-title {
  margin-bottom: 1rem;
}

.team-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.team-card {
  background-color: var(--color-olive-green);
  display: flex;
  flex-direction: column;
}

.team-container1 h2 {
  color: var(--color-black);
  font-weight: 900;
}

.team-image {
  width: 100%;
  aspect-ratio: 1 / 1.35;
}

.team-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-card-content {
  padding: 1rem;
}

/* -----------------contact-page---------------------- */

.contact-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.contact-card {
  padding: 1.5rem 0.5rem;
  border-radius: 8px;
  box-shadow: 0 1px 5px 0px var(--dark-black);
}

.contact-card-img {
  aspect-ratio: 3;
  overflow: hidden;
  text-align: center;
  align-content: center;
}

.contact-card-img img {
  width: 35%;
}

.contact-card-info {
  text-align: center;
}

.contact-form-section {
  background-color: var(--gray-white);
}

.contact-form-intro {
  margin-bottom: 2rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

button.form-submit {
  width: 10%;
}

.form-main-group {
  display: flex;
  justify-content: space-between;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 49%;
}

.form-group-message label {
  font-size: 1.25rem;
}

.form-group-message {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-size: 1.25rem;
}

textarea#message {
  height: 150px;
  padding: 10px 0 0 10px;
}

.form-group input {
  height: 3.5rem;
  padding-left: 10px;
}

/* FooterMain Page css */

.footer {
  background-color: var(--dark-black);
  color: var(--color-white);
}

.footer-main {
  padding-top: 5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.footer-section p {
  color: var(--color-white);
  font-weight: 500;
}
.footer-section {
  width: 80%;
  line-height: 2rem;
}

.footer-section.footer-contact p, .footer-section.footer-social p {
  margin-bottom: 10px;
}

.footer-section-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--color-white);
  line-height: 1.3;
}

li.footer-links-item {
  cursor: pointer;
  font-weight: 500;
  list-style: none;
}

li.footer-links-item a {
  color: #fff;
  text-decoration: none;
}

.footer-title {
  text-align: center;
}

.footer-title-word {
  color: var(--color-white);
  font-size: 20rem;
  font-weight: 700;
  opacity: 0.2;
  font-family: "Gilroy";
  text-transform: uppercase;
}

.Social-links {
  display: flex;
}

.Social-links img {
  width: 100%;
  min-width: 45px;
  max-width: 60px;
}

.footer-button {
  margin-top: 1rem;
}
p.highlight-paragraph {
  font-size: 1.4rem;
}
.video_review iframe {
  width: 75%;
  height: 650px;
}
@media (max-width: 1380px) {
  .footer-title-word {
    font-size: 16rem;
  }
}
@media (max-width: 1100px) {
  h1 {
    font-size: 6.5rem;
  }
  h2 {
    font-size: 4.5rem;
  }
  /* -------home-page------------ */
  .brand-images {
    grid-template-columns: repeat(2, 1fr);
  }
  .crousel-image-box {
    width: 100%;
    height: 100%;
  }

  .brand-footer {
    margin-top: 20px;
  }

  .review-slider .crousel-image-box {
    height: auto;
    width: 100%;
  }

  .member-text {
    width: 100%;
  }
 .achievement_img img {
  width: 90%;
 }

  .logo-card-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .discovery-image img {
    width: 100%;
  }

  .crousel-study-text p,
  .member-content p {
    font-size: 22px;
  }

  /* -------------service-page----- */

  .service-approach-details {
    grid-template-columns: repeat(2, 1fr);
  }

  /* ------------work-page---------- */

  .work-nav-container {
    grid-template-columns: repeat(3, 1fr);
    text-align: left;
  }

  .work-image img {
    height: auto;
  }

  .work-nav-content {
    gap: 2rem;
  }

  /* -------about-page---------- */

  .about-grow-cards,
  .team-container {
    grid-template-columns: repeat(2, 1fr);
  }

  /* -----------------contact-page ----------------*/

  .contact-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  /* -------footer------------- */

  .footer-title-word {
    font-size: 14rem;
  }
}

@media (max-width: 780px) {
  .video_review iframe {
    width: 99%;
    height: 400px;
  }
  section {
    padding: 2rem 0;
  }

  .container {
    padding: 0 2rem;
  }

  /* -----home-page--------- */
  .logo-card-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .review-slider .text-crousel {
    width: 100%;
  }

  .review-slider .crousel-study-text, .review-slider .crousel-study-image {
    width: 50%;
  }

  .member-content, .member-image, .achievement-content, .achievement-image {
    width: 100%;
  }

  .service-brand,
  .service-brand:nth-child(2) {
    flex-direction: column;
  }

  .service-brand-image {
    width: 100%;
  }

  button.form-submit {
    width: 15%;
  }

  /* ------footer---------- */
  .footer-title-word {
    font-size: 10rem;
  }

  .footer-main {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 2.75rem;
  }

  h2 {
    font-size: 2.125rem;
  }

  h4{
    font-size: 1.5rem;
  }

  .container {
    padding: 0 1rem;
  }

  .hero-text p {
    font-size: 1.875rem;
  }

  /* ------header------------ */

  .header-nav{
    display: none; 
  }

  .header-nav.open {
    display: grid; 
    z-index: 999;
    gap: 0;
  }

  .hamburger {
    display: flex; 
  }

  .header-nav.open .header-home {
    border-bottom: 1px solid;
  }

  .header-nav a {
    color: var(--color-black) !important;
  }

  .bar.open.left {
    transform: rotate(45deg) !important;
   }
   .bar.open.right {
    transform: rotate(-45deg);
    margin-top: -10px;
   }
   .bar.open.small {
    display: none;
  } 

  /* -------------home-page----------- */

  .crousel-study-main, .main-pop-up {
    flex-direction: column;
  }

  .logo-card-header h3 {
    font-size: 1.5rem;
  }

  .crousel-study-text,
  .crousel-study-image,
  .review-slider .crousel-study-image,
  .review-slider .crousel-study-text,
  .member-content,
  .member-image,
  .achievement-image,
  .achievement-content,
  .popup-image,
  .popup-text {
    width: 100%;
  }

  .achievement-text p {
    width: 100%;
  }

  .achievement-section {
    flex-direction: column;
  }

  .crousel-study-text {
    min-height: 300px;
  }

  .logo-card {
    height: 200px;
  }

  .brand-footer {
    text-align: center;
    width: 100%;
  }

  .achievement_img {
    height: 130px;
  }

  .achievement_img img {
    width: 100%;
    object-fit: cover;
    object-position: left;
    height: 90%;
}
.achievement-text {
  text-align: left !important;
  margin: 2rem 0 !important;
}
.visit-button {
  margin-left: 0 !important;
}

  .brand-text {
    width: 100%;
  }

  .logo-card-container {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
   }

  /* -----------service-page----------- */

  .member-section {
    flex-direction: column-reverse;
  }
  .service-brand-content {
    padding: 2rem;
  }

  /* ------work-page---------- */

  .work-nav-container,
  .service-approach-details {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }

  .work-nav-container, .work-nav-divider {
    display: none;
  }

  .work-nav-content,
  .brand-images{
    grid-template-columns: repeat(1, 1fr);
  }

  /* ------------about-page--------- */

  .about-grow-cards,
  .team-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .about-grow-card-title {
    margin-bottom: 0;
  }

  .discovery-main {
    flex-direction: column;
  }

  .team-card-content {
    text-align: center;
  }

  .discovery-image,
  .discovery-content {
    width: 100%;
    text-align: center;
  }

  /* --------------contact-page------------- */

  .contact-cards {
    grid-template-columns: repeat(1, 1fr);
  }

  .form-main-group {
    flex-direction: column;
    gap: 1rem;
  }

  .form-group {
    width: 100%;
  }

  button.form-submit {
    width: 35%;
    margin: auto;
  }

  .footer-title-word {
    font-size: 5rem;
  }

  .footer-main {
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem;
  }
}

@media (max-width: 390px) {

  h1 {
    font-size: 2.625rem;
  }

  .footer-title-word {
    font-size: 4rem;
  }
}
